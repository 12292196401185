<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PaymentMethodsList from '/~/components/payments/payments-methods-list.vue'
import { useAccess } from '/~/composables/access/use-access'
import { useCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'

const { configLoading } = usePaymentMethods()
const { splitPaymentOrderAllowed } = useProvider()
const { payment, currentFlowType } = useCheckout()
const route = useRoute()
const router = useRouter()
const { withAccessCheck } = useAccess()

onBeforeMount(() => {
  emitter.on('checkout-method-selected', goToCheckout)
})

onBeforeUnmount(() => {
  emitter.off('checkout-method-selected', goToCheckout)
})

function goToCheckout() {
  nextTick(() => {
    router.push({ name: 'purchase-checkout' })
  })
}

function addPaymentMethod() {
  router.push({
    hash: '#profile-payment-methods-add',
    query: route.query,
    params: {
      source: payment.orderType,
    },
  })
}
</script>

<template>
  <div>
    <base-back :to="{ name: 'purchase-checkout', query: $route.query }" />

    <div class="my-5 flex items-center justify-between">
      <h2 class="text-xl">Payment Methods</h2>
      <base-button
        v-if="
          !splitPaymentOrderAllowed(currentFlowType) &&
          payment.canAddPaymentMethods
        "
        look="link"
        :disabled="payment.submitting"
        @click="
          withAccessCheck({
            callback: addPaymentMethod,
            config: { actionType: 'paymentMethods' },
          })
        "
      >
        + Add Payment Method
      </base-button>
    </div>
    <base-loader v-if="configLoading" class="mt-[30px] w-full" />

    <payment-methods-list
      v-else
      :flow-type="FlowType.purchase"
      class="mt-[5px] space-y-6"
    />
  </div>
</template>
